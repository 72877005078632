$(function () {
    setHeaderScroll()
    hamburger()
    fancyBoxInit()
    siteSearch()
});


//Lightcase
$(document).ready(function() {
	$('a[data-rel^=lightcase]').lightcase();
});
