const siteSearch = () => {
  let button = document.getElementById("search-open");

  button.addEventListener("click", function(e) {
      e.preventDefault
      let search = document.getElementById("search-modal");
      search.classList.add("shown");
  });

  let buttonClose = document.getElementById("close-modal");

  buttonClose.addEventListener("click",function(e){
      e.preventDefault
      let searchRemove = document.getElementById("search-modal");
      searchRemove.classList.remove("shown");
  });
}
